import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo 
        title="404 - Not Found"
        description="A family owned and operated log home design and lumber mill located in Vermont. We manufacture quality custom log homes and dimensional milled lumber." 
      />
      <div className="container mx-auto pt-36 md:pt-48">
        <div className="mx-auto max-w-5xl md:text-center">
          <h1>404 - Page Not Found</h1>
          <p className="text-xl md:text-2xl max-w-xl md:mx-auto">Sorry the page you're looking for does not exist.</p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
